
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.approval-builder {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $base-gutter;
  }
  &__default {
    display: flex;
    gap: $base-gutter;
  }
  &__show-more {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
